var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('h4',{staticClass:"mt-20 mb-20"},[_vm._v("รายงานผลการนับคะแนนเลือกตั้ง ส.อบจ.สุรินทร์")]),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('b-card',{attrs:{"bg-variant":"light","sub-title":"1. ข้อมูลหน่วยเลือกตั้ง ส.อบจ.สุรินทร์"}},[_c('div',{staticClass:"table-responsive m-0"},[_c('table',{staticClass:"table table-bordered table-sm"},[_c('tbody',[_c('tr',[_c('td',{attrs:{"width":"125px"}},[_vm._v("หน่วยเลือกตั้ง")]),_c('td',[_vm._v("หน่วยเลือกตั้งที่ "+_vm._s(_vm.unit.unit_no)+" ต."+_vm._s(_vm.unit.sub_district_name))])]),_c('tr',[_c('td',{attrs:{"width":"125px"}},[_vm._v("เขตเลือกตั้ง")]),_c('td',[_vm._v("เขตเลือกตั้งที่ "+_vm._s(_vm.unit.area_no)+" อ."+_vm._s(_vm.unit.district_name)+" จ."+_vm._s(_vm.unit.province_name))])]),_c('tr',[_c('td',{attrs:{"width":"125px"}},[_vm._v("ผู้มีสิทธิเลือกตั้ง")]),_c('td',{staticClass:"form-inline pt-1 pb-1"},[_c('ValidationProvider',{attrs:{"name":"election_area_base","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"width-450 me-5",class:classes,attrs:{"size":"sm","name":"election_area_base","rows":"3","max-rows":"10"},model:{value:(_vm.unit.election_area_base),callback:function ($$v) {_vm.$set(_vm.unit, "election_area_base", $$v)},expression:"unit.election_area_base"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('td',{attrs:{"width":"125px"}},[_vm._v("สถานที่เลือกตั้ง")]),_c('td',{staticClass:"form-inline pt-1 pb-1"},[_c('ValidationProvider',{attrs:{"name":"election_place_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"width-450 me-5",class:classes,attrs:{"size":"sm","name":"election_place_name"},model:{value:(_vm.unit.election_place_name),callback:function ($$v) {_vm.$set(_vm.unit, "election_place_name", $$v)},expression:"unit.election_place_name"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])])])]),_c('b-card',{staticClass:"mt-3",attrs:{"bg-variant":"light","sub-title":"2. ข้อมูลการเลือกตั้ง ส.อบจ.สุรินทร์"}},[_c('div',{staticClass:"table-responsive m-0"},[_c('table',{staticClass:"table table-bordered table-sm"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("1. จำนวนผู้มีสิทธิเลือกตั้งในบัญชีรายชื่อผู้มีสิทธิเลือกตั้ง (คน)")]),_c('td',{staticClass:"form-inline pt-1 pb-1"},[_c('ValidationProvider',{attrs:{"name":"area_elector","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"width-200 me-5",class:classes,attrs:{"size":"sm","name":"area_elector"},model:{value:(_vm.unit.area_elector),callback:function ($$v) {_vm.$set(_vm.unit, "area_elector", $$v)},expression:"unit.area_elector"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('td',[_vm._v("2. จำนวนผู้มีสิทธิเลือกตั้งที่มาแสดงตนขอรับบัตรเลือกตั้ง (คน)")]),_c('td',{staticClass:"form-inline pt-1 pb-1"},[_c('ValidationProvider',{attrs:{"name":"area_comein","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"width-200 me-5",class:classes,attrs:{"size":"sm","name":"area_comein"},model:{value:(_vm.unit.area_comein),callback:function ($$v) {_vm.$set(_vm.unit, "area_comein", $$v)},expression:"unit.area_comein"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('td',[_vm._v("3. จำนวนบัตรเลือกตั้งที่ได้รับมาทั้งหมด (บัตร)")]),_c('td',{staticClass:"form-inline pt-1 pb-1"},[_c('ValidationProvider',{attrs:{"name":"area_receive","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"width-200 me-5",class:classes,attrs:{"size":"sm","name":"area_receive"},model:{value:(_vm.unit.area_receive),callback:function ($$v) {_vm.$set(_vm.unit, "area_receive", $$v)},expression:"unit.area_receive"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('td',[_vm._v("4. จำนวนบัตรเลือกตั้งที่ใช้ลงคะแนนเลือกตั้ง (บัตร)")]),_c('td',{staticClass:"form-inline pt-1 pb-1"},[_c('ValidationProvider',{attrs:{"name":"area_use","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"width-200 me-5",class:classes,attrs:{"size":"sm","name":"area_use"},model:{value:(_vm.unit.area_use),callback:function ($$v) {_vm.$set(_vm.unit, "area_use", $$v)},expression:"unit.area_use"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('td',[_vm._v("5. จำนวนบัตรดี (บัตร)")]),_c('td',{staticClass:"form-inline pt-1 pb-1"},[_c('ValidationProvider',{attrs:{"name":"area_good","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"width-200 me-5",class:classes,attrs:{"size":"sm","name":"area_good"},model:{value:(_vm.unit.area_good),callback:function ($$v) {_vm.$set(_vm.unit, "area_good", $$v)},expression:"unit.area_good"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('td',[_vm._v("6. จำนวนบัตรเสีย (บัตร)")]),_c('td',{staticClass:"form-inline pt-1 pb-1"},[_c('ValidationProvider',{attrs:{"name":"area_fail","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"width-200 me-5",class:classes,attrs:{"size":"sm","name":"area_fail"},model:{value:(_vm.unit.area_fail),callback:function ($$v) {_vm.$set(_vm.unit, "area_fail", $$v)},expression:"unit.area_fail"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('td',[_vm._v("7. จำนวนบัตรเลือกตั้งที่ทำเครื่องหมายไม่เลือกผู้สมัครใด (บัตร)")]),_c('td',{staticClass:"form-inline pt-1 pb-1"},[_c('ValidationProvider',{attrs:{"name":"area_no_vote","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"width-200 me-5",class:classes,attrs:{"size":"sm","name":"area_no_vote"},model:{value:(_vm.unit.area_no_vote),callback:function ($$v) {_vm.$set(_vm.unit, "area_no_vote", $$v)},expression:"unit.area_no_vote"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('td',[_vm._v("8. จำนวนบัตรเลือกตั้งที่เหลือ (บัตร)")]),_c('td',{staticClass:"form-inline pt-1 pb-1"},[_c('ValidationProvider',{attrs:{"name":"area_remain","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"width-200 me-5",class:classes,attrs:{"size":"sm","name":"area_remain"},model:{value:(_vm.unit.area_remain),callback:function ($$v) {_vm.$set(_vm.unit, "area_remain", $$v)},expression:"unit.area_remain"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v(" 9. ผู้สมัครรับเลือกตั้งได้คะแนนเรียงตามลำดับหมายเลขประจำตัวผู้สมัคร ดังนี้ "),_c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"table-responsive mb-0 mt-10"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"width":"110px"}},[_vm._v("หมายเลขผู้สมัคร")]),_c('th',{staticClass:"text-center"},[_vm._v("ชื่อตัว ชื่อสกุล")]),_c('th',{staticClass:"text-center"},[_vm._v("คะแนน")])])]),_c('tbody',_vm._l((_vm.applicant_areas),function(applicant){return _c('tr',{key:applicant.id},[_c('td',{staticClass:"text-center",attrs:{"width":"110px"}},[_c('h5',[_vm._v(_vm._s(applicant.number))])]),_c('td',[_c('h5',[_vm._v(_vm._s(applicant.prefix_name + applicant.first_name + ' ' + applicant.last_name))])]),_c('td',{staticClass:"form-inline pt-1 pb-1"},[_c('ValidationProvider',{attrs:{"name":'area_score_' + applicant.number,"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"width-200 me-5",class:classes,attrs:{"size":"lg","name":'area_score_' + applicant.number},model:{value:(_vm.unit['area_score_' + applicant.number]),callback:function ($$v) {_vm.$set(_vm.unit, 'area_score_' + applicant.number, $$v)},expression:"unit['area_score_' + applicant.number]"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])}),0)])])])],1)])])])])]),_c('b-alert',{staticClass:"mt-10",attrs:{"show":"","variant":"danger"}},[_c('h4',[_vm._v(" หมายเหตุ "),_c('small',[_vm._v("กฏสำหรับการป้อนข้อมูล")])]),_c('CardKeyInRole')],1),_c('hr'),_c('div',{staticClass:"col-12 text-right"},[_c('b-button',{staticClass:"ms-5",attrs:{"variant":"danger","size":"lg"},on:{"click":function($event){return _vm.storeData(false)}}},[_c('b-icon',{attrs:{"icon":"x-square","aria-hidden":"true"}}),_vm._v("   "+_vm._s(_vm.currentUser.group_id == 3 ? 'ยกเลิกการส่งผลคะแนน' : 'ยกเลิกการยืนยันผลคะแนน')+" ")],1),_c('b-button',{staticClass:"ms-5",attrs:{"variant":"success","size":"lg"},on:{"click":function($event){return _vm.storeData(true)}}},[_c('b-icon',{attrs:{"icon":"check2-square","aria-hidden":"true"}}),_vm._v("   "+_vm._s(_vm.currentUser.group_id == 3 ? 'บันทึกการส่งผลคะแนน' : 'บันทึกการยืนยันผลคะแนน')+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }