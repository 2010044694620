<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h4 class="mt-20 mb-20">รายงานผลการนับคะแนนเลือกตั้ง ส.อบจ.สุรินทร์</h4>
        <ValidationObserver tag="form" ref="observer">
          <b-card bg-variant="light" sub-title="1. ข้อมูลหน่วยเลือกตั้ง ส.อบจ.สุรินทร์">
            <div class="table-responsive m-0">
              <table class="table table-bordered table-sm">
                <tbody>
                  <tr>
                    <td width="125px">หน่วยเลือกตั้ง</td>
                    <td>หน่วยเลือกตั้งที่ {{ unit.unit_no }} ต.{{ unit.sub_district_name }}</td>
                  </tr>
                  <tr>
                    <td width="125px">เขตเลือกตั้ง</td>
                    <td>เขตเลือกตั้งที่ {{ unit.area_no }} อ.{{ unit.district_name }} จ.{{ unit.province_name }}</td>
                  </tr>
                  <tr>
                    <td width="125px">ผู้มีสิทธิเลือกตั้ง</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="election_area_base" rules="required" v-slot="{ classes, errors }">
                        <b-form-textarea size="sm" name="election_area_base" :class="classes" v-model="unit.election_area_base" class="width-450 me-5" rows="3" max-rows="10"></b-form-textarea>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td width="125px">สถานที่เลือกตั้ง</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="election_place_name" rules="required" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="election_place_name" :class="classes" v-model="unit.election_place_name" class="width-450 me-5"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>
          <b-card bg-variant="light" sub-title="2. ข้อมูลการเลือกตั้ง ส.อบจ.สุรินทร์" class="mt-3">
            <div class="table-responsive m-0">
              <table class="table table-bordered table-sm">
                <tbody>
                  <tr>
                    <td>1. จำนวนผู้มีสิทธิเลือกตั้งในบัญชีรายชื่อผู้มีสิทธิเลือกตั้ง (คน)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="area_elector" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="area_elector" :class="classes" v-model="unit.area_elector" class="width-200 me-5"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>2. จำนวนผู้มีสิทธิเลือกตั้งที่มาแสดงตนขอรับบัตรเลือกตั้ง (คน)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="area_comein" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="area_comein" :class="classes" v-model="unit.area_comein" class="width-200 me-5"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>3. จำนวนบัตรเลือกตั้งที่ได้รับมาทั้งหมด (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="area_receive" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="area_receive" :class="classes" v-model="unit.area_receive" class="width-200 me-5"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>4. จำนวนบัตรเลือกตั้งที่ใช้ลงคะแนนเลือกตั้ง (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="area_use" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="area_use" :class="classes" v-model="unit.area_use" class="width-200 me-5"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>5. จำนวนบัตรดี (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="area_good" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="area_good" :class="classes" v-model="unit.area_good" class="width-200 me-5"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>6. จำนวนบัตรเสีย (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="area_fail" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="area_fail" :class="classes" v-model="unit.area_fail" class="width-200 me-5"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>7. จำนวนบัตรเลือกตั้งที่ทำเครื่องหมายไม่เลือกผู้สมัครใด (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="area_no_vote" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="area_no_vote" :class="classes" v-model="unit.area_no_vote" class="width-200 me-5"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>8. จำนวนบัตรเลือกตั้งที่เหลือ (บัตร)</td>
                    <td class="form-inline pt-1 pb-1">
                      <ValidationProvider name="area_remain" rules="required|numeric" v-slot="{ classes, errors }">
                        <b-form-input size="sm" name="area_remain" :class="classes" v-model="unit.area_remain" class="width-200 me-5"></b-form-input>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      9. ผู้สมัครรับเลือกตั้งได้คะแนนเรียงตามลำดับหมายเลขประจำตัวผู้สมัคร ดังนี้
                      <b-container fluid>
                        <div class="table-responsive mb-0 mt-10">
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th class="text-center" width="110px">หมายเลขผู้สมัคร</th>
                                <th class="text-center">ชื่อตัว ชื่อสกุล</th>
                                <th class="text-center">คะแนน</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="applicant in applicant_areas" :key="applicant.id">
                                <td class="text-center" width="110px">
                                  <h5>{{ applicant.number }}</h5>
                                </td>
                                <td>
                                  <h5>{{ applicant.prefix_name + applicant.first_name + ' ' + applicant.last_name }}</h5>
                                </td>
                                <td class="form-inline pt-1 pb-1">
                                  <ValidationProvider :name="'area_score_' + applicant.number" rules="required|numeric" v-slot="{ classes, errors }">
                                    <b-form-input size="lg" :name="'area_score_' + applicant.number" :class="classes" v-model="unit['area_score_' + applicant.number]" class="width-200 me-5"></b-form-input>
                                    <span class="invalid-feedback">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>
          <b-alert show variant="danger" class="mt-10">
            <h4>
              หมายเหตุ
              <small>กฏสำหรับการป้อนข้อมูล</small>
            </h4>
            <CardKeyInRole />
          </b-alert>
          <hr />
          <div class="col-12 text-right">
            <b-button variant="danger" size="lg" class="ms-5" @click="storeData(false)">
              <b-icon icon="x-square" aria-hidden="true"></b-icon>
              &nbsp; {{ currentUser.group_id == 3 ? 'ยกเลิกการส่งผลคะแนน' : 'ยกเลิกการยืนยันผลคะแนน' }}
            </b-button>
            <b-button variant="success" size="lg" class="ms-5" @click="storeData(true)">
              <b-icon icon="check2-square" aria-hidden="true"></b-icon>
              &nbsp; {{ currentUser.group_id == 3 ? 'บันทึกการส่งผลคะแนน' : 'บันทึกการยืนยันผลคะแนน' }}
            </b-button>
          </div>
        </ValidationObserver>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CardKeyInRole from '../share/CardKeyInRole.vue'

export default {
  name: 'CouncilFormConfirm',
  components: {
    CardKeyInRole,
  },
  data() {
    return {
      election_unit_id: this.$props.id,
      unit: {},
      applicant_areas: [],
    }
  },
  props: {
    id: null,
  },
  mounted() {
    this.getData()
  },
  computed: {
    currentUser() {
      let user = this.$store.state.auth.user
      // user.group_id = 3
      // user.district_id = 3201
      // user.election_area_id = 1
      return user
    },
  },
  methods: {
    getData() {
      this.axios.get('election_units/' + this.election_unit_id).then((res) => {
        this.unit = res.data
        let param = {
          t: 'get_applicants',
          election_area_id: res.data.election_area_id,
        }
        this.axios.get('applicant_areas', { params: param }).then((res) => {
          this.applicant_areas = res.data
        })
      })
    },
    storeData(save_state) {
      if (parseInt(this.unit.area_elector) < parseInt(this.unit.area_comein)) {
        this.$toast.error('ข้อ 1 ต้องมากกว่า หรือ เท่ากับ ข้อ 2 กรุณาตรวจสอบ!')
      } else if (parseInt(this.unit.area_comein) != parseInt(this.unit.area_use)) {
        this.$toast.error('ข้อ 2 ไม่เท่ากับ ข้อ 4 กรุณาตรวจสอบ!')
      } else if (parseInt(this.unit.area_good) + parseInt(this.unit.area_fail) + parseInt(this.unit.area_no_vote) != parseInt(this.unit.area_use)) {
        this.$toast.error('ข้อ 5 + ข้อ 6 + ข้อ 7 ไม่เท่ากับ ข้อ 4 กรุณาตรวจสอบ!')
      } else if (parseInt(this.unit.area_use) + parseInt(this.unit.area_remain) != parseInt(this.unit.area_receive)) {
        this.$toast.error('ข้อ 4 + ข้อ 8 ไม่เท่ากับ ข้อ 3 กรุณาตรวจสอบ!')
      } else if (
        parseInt(this.unit.area_score_1) + parseInt(this.unit.area_score_2) + parseInt(this.unit.area_score_3) + parseInt(this.unit.area_score_4) + parseInt(this.unit.area_score_5) + parseInt(this.unit.area_score_6) + parseInt(this.unit.area_score_7) + parseInt(this.unit.area_score_8) + parseInt(this.unit.area_score_9) + parseInt(this.unit.area_score_10) !=
        parseInt(this.unit.area_good)
      ) {
        this.$toast.error('ผลรวมของคะแนนทั้งหมด ไม่เท่ากับ ข้อ 5 กรุณาตรวจสอบ!')
      } else {
        this.$refs.observer.validate().then((result) => {
          if (result) {
            let loader = this.$loading.show()
            if (this.currentUser.group_id == 3) {
              this.unit.area_confirm = save_state ? 1 : 0
            } else {
              this.unit.area_confirm = save_state ? 2 : 1
            }
            this.axios
              .put(`election_units/${this.unit.id}`, this.unit)
              .then((response) => {
                this.$nextTick(() => {
                  loader.hide()
                  if (this.currentUser.group_id == 3) {
                    if (this.unit.area_confirm == 0) {
                      this.$toast.warning('ระบบได้ทำการยกเลิกการส่งผลการนับคะแนนเรียบร้อยแล้ว')
                    } else {
                      this.$toast.success('ระบบได้ทำการบันทึกและส่งผลการนับคะแนนเรียบร้อยแล้ว')
                    }
                  } else {
                    if (this.unit.area_confirm == 1) {
                      this.$toast.warning('ระบบได้ทำการยกเลิกการยืนยันผลการนับคะแนนเรียบร้อยแล้ว')
                    } else {
                      this.$toast.success('ระบบได้ทำการบันทึกยืนยันผลการนับคะแนนเรียบร้อยแล้ว')
                    }
                  }
                  this.$emit('update-data', response.data)
                })
              })
              .catch((error) => {
                this.$nextTick(() => {
                  loader.hide()
                  this.$toast.error(error.response.data.message)
                })
              })
          } else {
            this.$toast.error('ข้อมูลไม่ครบถ้วนถูกต้องสมบูรณ์ กรุณาแก้ไขข้อมูลให้ถูกต้อง!')
          }
        })
      }
    },
  },
}
</script>

<style>
.width-45 {
  width: 45px !important;
}
.width-65 {
  width: 65px !important;
}
.width-200 {
  width: 200px !important;
}
.width-450 {
  width: 450px !important;
}
</style>
